import { compare } from "@/utils/common";
export default {
  data() {
    return {
      isAlbumsLoading: false,
      isFileLoading: false,
      isImagesLoading: false,
    }
  },
  computed: {
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
  },
  methods: {
    handleSeriesClear() {
      this.refreshSeriesOptions()
    },
    // 元素图
    addElementMedias(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.elementMedias.length,
        typeId: type,
        url: "",
      };
      this.form.elementMedias.push(item);
    },
    removeElementMedias(index) {
      this.form.elementMedias.splice(index, 1);
    },
    clearElementMediasVideoCover(index) {
      this.form.elementMedias = this.form.elementMedias.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    // 实拍图
    addPhotos(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.photos.length,
        typeId: type,
        url: "",
      };
      this.form.photos.push(item);
    },
    removePhotos(index) {
      this.form.photos.splice(index, 1);
    },
    clearPhotosVideoCover(index) {
      this.form.photos = this.form.photos.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    // normalMedias媒体
    addMedias(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.normalMedias.length,
        typeId: type,
        url: "",
      };
      this.form.normalMedias.push(item);
    },
    removeMedias(index) {
      this.form.normalMedias.splice(index, 1);
    },

    clearMediaVideoCover(index) {
      this.form.normalMedias = this.form.normalMedias.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    // 画册操作
    handleAlbumsBeforeUpload(file) {
      let allowType = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowType.includes(file.type)) {
        this.$message.error(`上传的图片格式不合法,格式需要为jpeg/jpg/png`);
        return false;
      }
      this.isAlbumsLoading = true;
      return true;
    },
    handleAlbumsSuccess(response, file) {
      this.form.albums.push({
        name: {
          cn: file.name,
          en: file.name,
        },
        url: file.response ? file.response : "",
        seq: this.form.albums.length + 1,
      });
      this.isAlbumsLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
    },
    handleAlbumsError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isAlbumsLoading = false;
    },
    handleRemoveAlbums(index) {
      this.form.albums.splice(index, 1);
    },

    // 文件
    handleFilesBeforeUpload() {
      this.isFileLoading = true;
      return true;
    },
    handleFilesSuccess(response, file) {
      this.form.files.push({
        name: {
          cn: file.name,
          en: file.name,
        },
        url: file.response ? file.response : "",
        seq: this.form.files.length + 1,
      });
      this.isFileLoading = false;
      this.$message.success(`文件${file.name}上传成功`);
    },
    handleFilesError(err, file) {
      console.error(err);
      this.$message.error(`文件${file.name}上传失败`);
      this.isFileLoading = false;
    },
    handleRemoveFiles(index) {
      this.form.files.splice(index, 1);
    },

    // 工具
    bulidMediaItem() {
      return {
        url: "",
        typeId: "",
        coverImageUrl: "",
        seq: 0,
      };
    },
    bulidSourceItem() {
      return {
        name: { ...this.localeColumns },
        url: "",
        seq: 0,
      };
    },
    buildI18nKeyValue(data) {
      if (data && Object.keys(data).length > 0) {
        let _data = { ...data };
        if (_data.i18n) {
          delete _data.i18n;
          return _data;
        }
        return _data;
      }
      return this.localeColumns;
    },
    sortArr(data = []) {
      if (data.length <= 0) {
        return data;
      }
      return data.sort(compare("seq"));
    },
    formatArrData(data = []) {
      if (data.length <= 0) {
        return []
      }
      return data
        .filter(item => {
          return item.url !== ""
        })
        .map((item, index) => {
          item.seq = index;
          return item
        })
    },
    formatDataToids(data = []) {
      if (data.length <= 0) {
        return data;
      }
      return data.map((item) => item.id);
    },

  }
}