<template>
  <div class="form-wrap">
    <div class="form-container">
      <el-form
        :model="form"
        ref="form"
        v-loading="loading"
        label-width="auto"
        :rules="rules"
        class="wg-product-ruleForm"
        label-position="top"
        :disabled="submitting"
      >
        <LocaleFormTab @change="onLocaleChange" style="custom-locale-form-tab">
          <template #cn>
            <FormBlock name="基本信息(中文)">
              <el-row :gutter="10">
                <el-col :sm="24" :md="12" :lg="8">
                  <el-form-item label="中文名称" prop="name.cn">
                    <el-input
                      v-model="form.name.cn"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :sm="24" :md="12" :lg="8">
                  <el-form-item
                    label="中文一句话描述"
                    prop="onelineDescription.cn"
                  >
                    <el-input
                      v-model="form.onelineDescription.cn"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="8">
                  <el-form-item label="中文海报广告语" prop="posterSlogan.cn">
                    <el-input
                      v-model="form.posterSlogan.cn"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12">
                  <el-form-item label="中文简介" prop="introduction.cn">
                    <el-input
                      v-model="form.introduction.cn"
                      clearable
                      placeholder="请输入"
                      type="textarea"
                      :rows="6"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12">
                  <el-form-item label="中文描述" prop="description.cn">
                    <el-input
                      v-model="form.description.cn"
                      clearable
                      placeholder="请输入"
                      type="textarea"
                      :rows="6"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </FormBlock>
          </template>
          <template #en>
            <FormBlock name="基本信息(英文)">
              <el-row :gutter="10">
                <el-col :sm="24" :md="12" :lg="8">
                  <el-form-item label="英文名称" prop="name.en">
                    <el-input
                      v-model="form.name.en"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :sm="24" :md="12" :lg="8">
                  <el-form-item
                    label="英文一句话描述"
                    prop="onelineDescription.en"
                  >
                    <el-input
                      v-model="form.onelineDescription.en"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="8">
                  <el-form-item label="英文海报广告语" prop="posterSlogan.en">
                    <el-input
                      v-model="form.posterSlogan.en"
                      clearable
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12">
                  <el-form-item label="英文简介" prop="introduction.en">
                    <el-input
                      v-model="form.introduction.en"
                      clearable
                      placeholder="请输入"
                      type="textarea"
                      :rows="6"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="12" :lg="12">
                  <el-form-item label="英文描述" prop="description.en">
                    <el-input
                      v-model="form.description.en"
                      clearable
                      placeholder="请输入"
                      type="textarea"
                      :rows="6"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </FormBlock>
          </template>
        </LocaleFormTab>

        <FormBlock name="基本信息">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="编号" prop="code" class="base-input">
                <el-input
                  v-model="form.code"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item
                label="系列"
                prop="seriesIds"
                class="custom-form-select"
              >
                <el-select
                  v-model="form.seriesIds"
                  filterable
                  remote
                  multiple
                  reserve-keyword
                  placeholder="请输入系列中文名查询"
                  clearable
                  :remote-method="remoteMethod"
                  class="form-select"
                  :loading="seriesLoading"
                  @clear="handleSeriesClear"
                >
                  <el-option
                    v-for="item in seriesOptions"
                    :key="item.id"
                    :label="`${item.name.i18n}`"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="色系" prop="colorId">
                <el-select
                  v-model="form.colorId"
                  placeholder="请选择色系"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in colorsOptions"
                    :key="item.id"
                    :label="item.name.i18n"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <el-form-item label="次序（数值越大越靠前）" prop="seq">
                <el-input type="number" v-model="form.seq" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </FormBlock>

        <FormBlock name="封面/海报/图片集">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="产品元素图" prop="thumbnailUrl">
                <SingleImgUpload v-model="form.thumbnailUrl" />
              </el-form-item>
              <p class="form-tips">
                提示：产品缩略图,图片尺寸的宽高为1:1的图片,建议大小保持在1MB以内
              </p>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品应用效果图" prop="coverImageUrl">
                <SingleImgUpload v-model="form.coverImageUrl" />
              </el-form-item>
              <p class="form-tips">
                提示：产品缩略图,图片尺寸的宽高为1:1的图片,建议大小保持在1MB以内
              </p>
            </el-col>
            <el-col :span="6" v-if="false">
              <el-form-item label="渲染图" prop="renderingImageUrl">
                <SingleImgUpload v-model="form.renderingImageUrl" />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="海报图" prop="posterImageUrl">
                <SingleImgUpload v-model="form.posterImageUrl" />
              </el-form-item>
              <p class="form-tips">
                提示：用作海报宣传图，建议宽高【1125x2436】等同比例（宽高比0.46）尺寸的图片,建议大小保持在1.5MB以内
              </p>
            </el-col>
            <el-col :span="24">
              <el-form-item label="元素图(显示顺序从左到右)" prop="medias">
                <DraggbleSort
                  syncSortKey
                  v-model="form.elementMedias"
                  class="medias-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <ProductImgUpload
                      v-if="slotProps.item.typeId == 'IMAGE'"
                      class="medias-item"
                      tips="选择图片"
                      v-model="slotProps.item.url"
                      @remove="removeElementMedias(slotProps.index)"
                    />
                    <div
                      class="video-medias-item"
                      v-if="slotProps.item.typeId == 'VIDEO'"
                    >
                      <ProductImgUpload
                        class="medias-item"
                        tips="选择封面图"
                        v-model="slotProps.item.coverImageUrl"
                        @remove="clearElementMediasVideoCover(slotProps.index)"
                      />

                      <ProductVideoItem
                        v-if="slotProps.item.typeId == 'VIDEO'"
                        :showDeleteIcon="false"
                        class="medias-item"
                        v-model="slotProps.item.url"
                      />

                      <i
                        class="el-icon-delete remove-video-icon"
                        title="点击删除"
                        @click="removeElementMedias(slotProps.index)"
                      ></i>
                    </div>
                  </template>
                </DraggbleSort>
                <div class="add-madias-item">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-picture-outline"
                      @click="addElementMedias('IMAGE')"
                      >添加图片</el-button
                    >
                    <!-- <el-button
                      type="primary"
                      size="small"
                      @click="addElementMedias('VIDEO')"
                      >添加视频<i
                        class="el-icon-video-camera-solid el-icon--right"
                      ></i
                    ></el-button> -->
                  </el-button-group>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="实拍图(显示顺序从左到右)" prop="photos">
                <DraggbleSort
                  syncSortKey
                  v-model="form.photos"
                  class="medias-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <ProductImgUpload
                      v-if="slotProps.item.typeId == 'IMAGE'"
                      class="medias-item"
                      tips="选择图片"
                      v-model="slotProps.item.url"
                      @remove="removePhotos(slotProps.index)"
                    />
                    <div
                      class="video-medias-item"
                      v-if="slotProps.item.typeId == 'VIDEO'"
                    >
                      <ProductImgUpload
                        class="medias-item"
                        tips="选择封面图"
                        v-model="slotProps.item.coverImageUrl"
                        @remove="clearPhotosVideoCover(slotProps.index)"
                      />

                      <ProductVideoItem
                        v-if="slotProps.item.typeId == 'VIDEO'"
                        :showDeleteIcon="false"
                        class="medias-item"
                        v-model="slotProps.item.url"
                      />

                      <i
                        class="el-icon-delete remove-video-icon"
                        title="点击删除"
                        @click="removePhotos(slotProps.index)"
                      ></i>
                    </div>
                  </template>
                </DraggbleSort>
                <div class="add-madias-item">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-picture-outline"
                      @click="addPhotos('IMAGE')"
                      >添加图片</el-button
                    >
                    <!-- <el-button
                      type="primary"
                      size="small"
                      @click="addPhotos('VIDEO')"
                      >添加视频<i
                        class="el-icon-video-camera-solid el-icon--right"
                      ></i
                    ></el-button> -->
                  </el-button-group>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="多媒体数据(显示顺序从左到右)" prop="medias">
                <p class="form-tips">
                  提示：以前小程序中的产品详情页的媒体数据，现在暂时没有使用
                </p>
                <DraggbleSort
                  syncSortKey
                  v-model="form.normalMedias"
                  class="medias-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <ProductImgUpload
                      v-if="slotProps.item.typeId == 'IMAGE'"
                      class="medias-item"
                      v-model="slotProps.item.url"
                      @remove="removeMedias(slotProps.index)"
                    />
                    <div
                      v-if="slotProps.item.typeId == 'VIDEO'"
                      class="video-medias-item"
                    >
                      <ProductVideoItem
                        :showDeleteIcon="false"
                        class="medias-item"
                        v-model="slotProps.item.url"
                      />
                      <ProductImgUpload
                        class="medias-item"
                        tips="选择图片"
                        v-model="slotProps.item.coverImageUrl"
                        @remove="clearMediaVideoCover(slotProps.index)"
                      />
                      <i
                        class="el-icon-delete remove-video-icon"
                        title="点击删除"
                        @click="removeMedias(slotProps.index)"
                      ></i>
                    </div>
                  </template>
                </DraggbleSort>
                <div class="add-madias-item">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-picture-outline"
                      @click="addMedias('IMAGE')"
                      >添加图片</el-button
                    >
                    <el-button
                      type="primary"
                      size="small"
                      @click="addMedias('VIDEO')"
                      >添加视频<i
                        class="el-icon-video-camera-solid el-icon--right"
                      ></i
                    ></el-button>
                  </el-button-group>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="图片集" prop="albums">
                <p class="form-tips">
                  提示：该图片集维护的是PC官网中的图册
                </p>
                <el-upload
                  :disabled="isAlbumsLoading"
                  class="upload-albums"
                  ref="albumsUpload"
                  :action="onUploadUrl"
                  :headers="headers"
                  :before-upload="handleAlbumsBeforeUpload"
                  :on-success="handleAlbumsSuccess"
                  :on-error="handleAlbumsError"
                  :auto-upload="true"
                  :show-file-list="false"
                  :multiple="true"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    :icon="
                      isAlbumsLoading ? 'el-icon-loading' : 'el-icon-upload2'
                    "
                    :disabled="isAlbumsLoading"
                    >{{ isAlbumsLoading ? "上传中..." : "选取文件" }} ({{
                      form.albums.length
                    }})</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    <p>限制文件类型为jpeg、jpg、png</p>
                    <p>
                      图册的图片尺寸不限制，但是为了客户端显示整齐，建议尽量都保持同等比例。
                    </p>
                    <p>
                      图片大小尽量保持在1-2MB以内,无严格限制,图片过大会导致显示缓慢
                    </p>
                  </div>
                </el-upload>
                <DraggbleSort
                  syncSortKey
                  v-model="form.albums"
                  class="file-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <div class="file-item">
                      <div class="image-wrapper">
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="slotProps.item.url"
                          :preview-src-list="
                            form.albums.map((item) => item.url)
                          "
                        >
                        </el-image>
                        <!-- <img :src="slotProps.item.url" /> -->
                      </div>
                      <div class="file-content">
                        <el-input
                          size="small"
                          v-model="slotProps.item.name.cn"
                          placeholder="请输入中文名称"
                          ><template slot="prepend">中文</template></el-input
                        >
                        <el-input
                          size="small"
                          v-model="slotProps.item.name.en"
                          placeholder="请输入英文名称"
                          ><template slot="prepend">英文</template></el-input
                        >
                      </div>
                      <div
                        class="delete-btn"
                        @click="handleRemoveAlbums(slotProps.index)"
                      >
                        <i class="el-icon-delete"></i>
                      </div>
                      <div class="darg-btn">
                        <i class="el-icon-rank"></i>
                      </div>
                    </div>
                  </template>
                </DraggbleSort>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="可下载附件" prop="files">
                <el-upload
                  :disabled="isFileLoading"
                  class="upload-albums"
                  ref="albumsUpload"
                  :action="onUploadUrl"
                  :headers="headers"
                  :before-upload="handleFilesBeforeUpload"
                  :on-success="handleFilesSuccess"
                  :on-error="handleFilesError"
                  :auto-upload="true"
                  :show-file-list="false"
                  :multiple="true"
                >
                  <el-button
                    slot="trigger"
                    size="small"
                    type="primary"
                    :icon="
                      isFileLoading ? 'el-icon-loading' : 'el-icon-upload2'
                    "
                    :disabled="isFileLoading"
                    >{{ isFileLoading ? "上传中..." : "选取文件" }} ({{
                      form.files.length
                    }})</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    文件类型不限制，单个文件最大建议保持在15MB以内
                  </div>
                </el-upload>
                <DraggbleSort
                  syncSortKey
                  v-model="form.files"
                  class="file-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <div class="file-item">
                      <div class="image-wrapper">
                        <a
                          :href="slotProps.item.url"
                          target="_blank"
                          download="true"
                          rel="noopener noreferrer"
                          title="点击预览"
                        >
                          <i class="el-icon-document"></i>
                        </a>
                      </div>
                      <div class="file-content">
                        <el-input
                          size="small"
                          v-model="slotProps.item.name.cn"
                          placeholder="请输入中文名称"
                          ><template slot="prepend">中文</template></el-input
                        >
                        <el-input
                          size="small"
                          v-model="slotProps.item.name.en"
                          placeholder="请输入英文名称"
                          ><template slot="prepend">英文</template></el-input
                        >
                      </div>
                      <div
                        class="delete-btn"
                        @click="handleRemoveFiles(slotProps.index)"
                      >
                        <i class="el-icon-delete"></i>
                      </div>
                      <div class="darg-btn">
                        <i class="el-icon-rank"></i>
                      </div>
                    </div>
                  </template>
                </DraggbleSort>
              </el-form-item>
            </el-col>
          </el-row>
        </FormBlock>
      </el-form>
    </div>
    <div class="control-btn-group" v-if="isEmprty(currentCopyId)">
      <el-button
        @click="onCancel"
        v-if="isEmprty(currentId)"
        :disabled="submitting"
        >重 置</el-button
      >
      <el-button
        v-if="isEmprty(currentId)"
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >保存新增</el-button
      >
      <el-button
        v-if="!isEmprty(currentId)"
        type="warning"
        :loading="submitting"
        @click="submitEditForm('form')"
        >保存修改</el-button
      >
    </div>
    <div class="control-btn-group" v-if="!isEmprty(currentCopyId)">
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >复 制</el-button
      >
    </div>
  </div>
</template>

<script>
import { isEmprty } from "@/utils/validate";
import { createUniqueString } from "@/utils/common";
import { PostProducts, PutProducts, GetProductsById } from "../api";
import API_CONFIG from "@/request/api.config";
import { getToken } from "@/utils/auth";
import { ShowApiError } from "@/request/error";
import DraggbleSort from "@/components/DraggbleSort";
import SingleImgUpload from "@/components/SingleImgUpload";
import FormBlock from "@/components/FormBlock";
import ProductVideoItem from "@/components/ProductVideoItem";
import ProductImgUpload from "@/components/ProductImgUpload";
import { GetSeriesList } from "@/views/productSeries/api";
import LocaleFormTab from "@/components/LocaleFormTab";
import form from "../mixins/form";
import options from "../mixins/options";
export default {
  name: "ProductForm",
  props: {
    productId: {
      type: [String, Number],
      default: null,
    },
    copyId: {
      type: [String, Number],
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [form, options],
  components: {
    FormBlock,
    SingleImgUpload,
    DraggbleSort,
    ProductVideoItem,
    ProductImgUpload,
    LocaleFormTab,
  },
  data() {
    return {
      showDrawer: false,
      currentLocalName: "cn",
      currentId: null,
      currentCopyId: null,
      loading: false,
      submitting: false,
      showAddMediaDialog: false,
      focusIndex: 0,
      form: {},
      rules: {
        "name.cn": [
          { required: true, message: "请输入中文名称", trigger: "blur" },
        ],
        "name.en": [
          { required: true, message: "请输入英文名称", trigger: "blur" },
        ],
        thumbnailUrl: [
          { required: true, message: "请上传产品元素图", trigger: "change" },
        ],
        coverImageUrl: [
          {
            required: true,
            message: "请上传产品应用效果图",
            trigger: "change",
          },
        ],
        seriesIds:[
        { required: true, message: "请选择产品系列", trigger: "change" },
        ]
      },
    };
  },
  computed: {
    headers() {
      return {
        Authorization: getToken(),
      };
    },
    onUploadUrl() {
      return API_CONFIG.BASE_V1_API + "/oss/upload/cdn";
    },
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
  },
  created() {
    this.initForm();
    this.initFormOptions();
  },
  watch: {
    productId: {
      handler(value) {
        if (!isEmprty(value)) {
          this.currentId = value;
          this.getFormData();
        } else {
          this.currentId = null;
          this.initForm();
        }
        this.initFormOptions();
      },
      immediate: true,
    },
    copyId:{
      handler(value) {
        if (!isEmprty(value)) {
          this.currentCopyId = value;
          this.getFormData(value);
        } else {
          this.currentCopyId = null;
          this.initForm();
        }
        this.initFormOptions();
      },
      immediate: true,
    },
    
    show: {
      handler(value) {
        this.showDrawer = value;
        if (this.currentId) {
          this.getFormData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    createUniqueString,
    GetSeriesList,
    onLocaleChange(e) {
      let name = e.name;
      this.currentLocalName = name;
    },
    formatForm() {
      return {
        ...this.form,
        code: this.form.code == "" ? null : this.form.code,
        normalMedias: this.formatArrData(this.form.normalMedias),
        elementMedias: this.formatArrData(this.form.elementMedias),
        photos: this.formatArrData(this.form.photos),
        albums: this.formatArrData(this.form.albums),
        files: this.formatArrData(this.form.files),
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostProducts([this.formatForm()])
            .then(() => {
              this.submitting = false;
              this.currentCopyId = null;
              this.initForm();
              this.$message.success("新增成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("批量新增异常", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    submitEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutProducts([
            {
              id: this.currentId,
              ...this.formatForm(),
            },
          ])
            .then(() => {
              this.submitting = false;
              this.$message.success("修改成功");
              this.getFormData();
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("批量新增异常", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id) {
      this.loading = true;
      let productId = id ? id:this.currentId;
      GetProductsById(productId)
        .then((res) => {
          let _data = res.data;
          this.initForm();
          this.reviewFormByData(_data);
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败", err);
        });
    },
    reviewFormByData(_data = {}) {
      this.initForm();
      if (_data && Object.keys(_data).length > 0) {
        for (const key in this.form) {
          if (Object.hasOwnProperty.call(_data, key)) {
            this.form[key] = _data[key];
          }
        }
      }
      this.form = {
        ...this.form,
        normalMedias: this.sortArr(_data.normalMedias),
        elementMedias: this.sortArr(_data.elementMedias),
        photos: this.sortArr(_data.photos),
        albums: this.sortArr(_data.albums),
        files: this.sortArr(_data.files),
        seriesIds: _data.series.map((item) => item.id),
      };
      this.refreshSeriesOptions({
        id: this.form.seriesIds,
      });
    },

    initForm() {
      this.form = {
        name: { ...this.localeColumns },
        seriesIds: [],
        code: null,
        colorId: null,
        introduction: { ...this.localeColumns },
        description: { ...this.localeColumns },
        onelineDescription: { ...this.localeColumns },
        posterSlogan: { ...this.localeColumns },
        posterImageUrl: "",
        coverImageUrl: "",
        renderingImageUrl: "",
        thumbnailUrl: "",
        normalMedias: [],
        elementMedias: [],
        photos: [],
        albums: [],
        files: [],
        seq: 0,
      };

      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].clearValidate();
        }
      });
    },

    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-locale-form-tab {
  padding-left: 10px;
  background-color: #fff;
}
.wg-product-ruleForm {
  box-sizing: border-box;
  width: 100%;
  .form-tips {
    font-size: 12px;
    margin: 0;
    color: #989898;
  }
  .poster-img {
    width: 150px;
    height: 326px;
  }
  .icon-btn {
    font-size: 20px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .base-input {
    ::v-deep {
      .el-form-item__content {
        height: 40px;
        overflow: hidden;
      }
      .el-input__inner {
        height: 40px;
      }
    }
  }
}

.box-card {
  margin-bottom: 10px;
  &:hover {
    background-color: #f1f1f1;
  }
}
.form-select {
  width: 100%;
}
.medias-list-wrap {
  display: inline-block;
  max-width: 100%;
  ::v-deep {
    .transition-el {
      max-height: none;
    }
  }
  .medias-item {
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  .video-medias-item {
    box-sizing: border-box;
    padding: 5px 0;
    margin: 5px;
    width: 280px;
    height: 100px;
    position: relative;
    padding-right: 20px;
    background-color: #ddd;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .remove-video-icon {
      position: absolute;
      bottom: 5px;
      right: 2px;
      font-size: 16px;
      cursor: pointer;
      color: #c51515;
    }
    ::v-deep {
      .basic-upload-wrap {
        width: 150px;
        height: 90px;
      }
      .img-upload-wrap {
        width: 90px;
        height: 90px;
      }
    }
  }
}
.add-madias-item {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 30px;
  margin: 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}
.models-list-wrap {
  width: 100%;
  max-width: 100%;
  .box-card {
    width: auto;
    cursor: move;
  }
  ::v-deep {
    .transition-el {
      box-sizing: border-box;
      flex-direction: row;
      max-height: 100%;
    }
  }
}

.form-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  .custom-form-select {
    ::v-deep {
      .el-form-item__content {
        max-height: 40px;
      }
    }
  }

  .form-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-bottom: 70px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .control-btn-group {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ddd;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 2006;
    text-align: right;
  }
  .file-list-wrap {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    min-height: 300px;
    border: 1px dashed #f1f1f1;
    background-color: #fffdfc;
    // resize: vertical;
    // overflow: hidden;
    ::v-deep {
      .transition-el {
        box-sizing: border-box;
        width: 100%;
        padding-right: 10px;
      }
      .draggable-item {
        width: 100%;
      }
    }
    .file-item {
      box-sizing: border-box;
      width: 100%;
      height: 120px;
      padding: 10px 50px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      gap: 10px;
      border: 1px solid #f1f1f1;
      color: #989898;
      overflow: hidden;

      .darg-btn {
        width: 40px;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        font-size: 20px;
        color: inherit;
        cursor: move;
      }
      .delete-btn {
        width: 40px;
        position: absolute;
        top: 50%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        font-size: 20px;
        color: inherit;
        cursor: pointer;
        &:hover {
          color: #ff3d3d;
        }
      }
      &:hover {
        // color: #fff;
        // background-color: hsl(15, 88%, 55%);
        border: 1px solid hsl(15, 88%, 55%);
        .darg-btn {
          color: #fff;
          background-color: hsl(15, 88%, 55%);
        }
      }
      .image-wrapper {
        box-sizing: border-box;
        padding: 4px;
        flex: 130px 0 0;
        height: 100%;
        border: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 56px;
        overflow: hidden;
        color: #ff3d3d;
        align-self: start;
        background-color: #f7f5f5;
        a {
          color: inherit;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        ::v-deep {
          .el-image {
            background-color: transparent;
          }
          .el-image__inner {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .file-content {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
</style>
